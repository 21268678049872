import * as Types from "./graphql.types";

export type ComponentReferenceFields_Car_Fragment = {
    __typename: "Car";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ContentTag_Fragment = {
    __typename: "ContentTag";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Driver_Fragment = {
    __typename: "Driver";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ExternalLink_Fragment = {
    __typename: "ExternalLink";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Footer_Fragment = {
    __typename: "Footer";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_LanguageSelectorItem_Fragment = {
    __typename: "LanguageSelectorItem";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_MainNavigation_Fragment = {
    __typename: "MainNavigation";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Microcopy_Fragment = {
    __typename: "Microcopy";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_MicrocopySet_Fragment = {
    __typename: "MicrocopySet";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModalLink_Fragment = {
    __typename: "ModalLink";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleAccordion_Fragment = {
    __typename: "ModuleAccordion";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleAudioPlayer_Fragment = {
    __typename: "ModuleAudioPlayer";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleCarTechSpecs_Fragment = {
    __typename: "ModuleCarTechSpecs";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleCarousel_Fragment = {
    __typename: "ModuleCarousel";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleCircuit_Fragment = {
    __typename: "ModuleCircuit";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleDriverList_Fragment = {
    __typename: "ModuleDriverList";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleGallery_Fragment = {
    __typename: "ModuleGallery";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleIframe_Fragment = {
    __typename: "ModuleIframe";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleImage_Fragment = {
    __typename: "ModuleImage";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleListenToTheEngine_Fragment = {
    __typename: "ModuleListenToTheEngine";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleMediaFeature_Fragment = {
    __typename: "ModuleMediaFeature";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModulePageLinkTile_Fragment = {
    __typename: "ModulePageLinkTile";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleQuickLinks_Fragment = {
    __typename: "ModuleQuickLinks";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleQuote_Fragment = {
    __typename: "ModuleQuote";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleRichText_Fragment = {
    __typename: "ModuleRichText";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleSideBySide_Fragment = {
    __typename: "ModuleSideBySide";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleSpacer_Fragment = {
    __typename: "ModuleSpacer";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleSplitLayout_Fragment = {
    __typename: "ModuleSplitLayout";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleTable_Fragment = {
    __typename: "ModuleTable";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_ModuleVideo_Fragment = {
    __typename: "ModuleVideo";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Navigation_Fragment = {
    __typename: "Navigation";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageArticle_Fragment = {
    __typename: "PageArticle";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageBasic_Fragment = {
    __typename: "PageBasic";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageCar_Fragment = {
    __typename: "PageCar";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageCategory_Fragment = {
    __typename: "PageCategory";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageDriver_Fragment = {
    __typename: "PageDriver";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageHomepage_Fragment = {
    __typename: "PageHomepage";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageRaceEvent_Fragment = {
    __typename: "PageRaceEvent";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageRaceSeries_Fragment = {
    __typename: "PageRaceSeries";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageSearch_Fragment = {
    __typename: "PageSearch";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PageTeam_Fragment = {
    __typename: "PageTeam";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PartAccordionItem_Fragment = {
    __typename: "PartAccordionItem";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PartCircuitHotspot_Fragment = {
    __typename: "PartCircuitHotspot";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PartMainNavigationAccordion_Fragment = {
    __typename: "PartMainNavigationAccordion";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PartMainNavigationItem_Fragment = {
    __typename: "PartMainNavigationItem";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Partner_Fragment = {
    __typename: "Partner";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_PartnerSet_Fragment = {
    __typename: "PartnerSet";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_SeoMetadata_Fragment = {
    __typename: "SeoMetadata";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_SiteSettings_Fragment = {
    __typename: "SiteSettings";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFields_Team_Fragment = {
    __typename: "Team";
    sys: { __typename?: "Sys"; id: string };
};

export type ComponentReferenceFieldsFragment =
    | ComponentReferenceFields_Car_Fragment
    | ComponentReferenceFields_ContentTag_Fragment
    | ComponentReferenceFields_Driver_Fragment
    | ComponentReferenceFields_ExternalLink_Fragment
    | ComponentReferenceFields_Footer_Fragment
    | ComponentReferenceFields_LanguageSelectorItem_Fragment
    | ComponentReferenceFields_MainNavigation_Fragment
    | ComponentReferenceFields_Microcopy_Fragment
    | ComponentReferenceFields_MicrocopySet_Fragment
    | ComponentReferenceFields_ModalLink_Fragment
    | ComponentReferenceFields_ModuleAccordion_Fragment
    | ComponentReferenceFields_ModuleAudioPlayer_Fragment
    | ComponentReferenceFields_ModuleCarTechSpecs_Fragment
    | ComponentReferenceFields_ModuleCarousel_Fragment
    | ComponentReferenceFields_ModuleCircuit_Fragment
    | ComponentReferenceFields_ModuleDriverList_Fragment
    | ComponentReferenceFields_ModuleGallery_Fragment
    | ComponentReferenceFields_ModuleIframe_Fragment
    | ComponentReferenceFields_ModuleImage_Fragment
    | ComponentReferenceFields_ModuleListenToTheEngine_Fragment
    | ComponentReferenceFields_ModuleMediaFeature_Fragment
    | ComponentReferenceFields_ModulePageLinkTile_Fragment
    | ComponentReferenceFields_ModuleQuickLinks_Fragment
    | ComponentReferenceFields_ModuleQuote_Fragment
    | ComponentReferenceFields_ModuleRichText_Fragment
    | ComponentReferenceFields_ModuleSideBySide_Fragment
    | ComponentReferenceFields_ModuleSpacer_Fragment
    | ComponentReferenceFields_ModuleSplitLayout_Fragment
    | ComponentReferenceFields_ModuleTable_Fragment
    | ComponentReferenceFields_ModuleVideo_Fragment
    | ComponentReferenceFields_Navigation_Fragment
    | ComponentReferenceFields_PageArticle_Fragment
    | ComponentReferenceFields_PageBasic_Fragment
    | ComponentReferenceFields_PageCar_Fragment
    | ComponentReferenceFields_PageCategory_Fragment
    | ComponentReferenceFields_PageDriver_Fragment
    | ComponentReferenceFields_PageHomepage_Fragment
    | ComponentReferenceFields_PageRaceEvent_Fragment
    | ComponentReferenceFields_PageRaceSeries_Fragment
    | ComponentReferenceFields_PageSearch_Fragment
    | ComponentReferenceFields_PageTeam_Fragment
    | ComponentReferenceFields_PartAccordionItem_Fragment
    | ComponentReferenceFields_PartCircuitHotspot_Fragment
    | ComponentReferenceFields_PartMainNavigationAccordion_Fragment
    | ComponentReferenceFields_PartMainNavigationItem_Fragment
    | ComponentReferenceFields_Partner_Fragment
    | ComponentReferenceFields_PartnerSet_Fragment
    | ComponentReferenceFields_SeoMetadata_Fragment
    | ComponentReferenceFields_SiteSettings_Fragment
    | ComponentReferenceFields_Team_Fragment;

export const ComponentReferenceFieldsFragmentDoc = `
    fragment ComponentReferenceFields on Entry {
  sys {
    id
  }
  __typename
}
    `;
