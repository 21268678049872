/**
 * Get the language for tracking - returns the ISO 639-1 language code
 * @param locale
 */
export const getLanguageForTracking = (locale: string | undefined) => {
    const localeTracking = locale || process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

    return localeTracking.split("-")[0];
};

/**
 * Get the country for tracking - returns the ISO3166 ALPHA2 country code or "international"
 * @param locale
 */
export const getCountryForTracking = (locale: string | undefined) => {
    if (!locale || locale === "en") {
        return "international";
    }

    return locale.toUpperCase().split("-")[1];
};

export const getPageId = (asPath: string | undefined) => {
    return `https://` + process.env.NEXT_PUBLIC_HOSTNAME + asPath;
};
