import type { Document, Text } from "@contentful/rich-text-types";
import { getComputedSlug } from "@/common/helpers/slug";
import type { ISitemapField } from "next-sitemap";
import type { Entry } from "contentful";

export const isRobotNoFollow = (value: string): boolean => value?.toLowerCase() === "nofollow";

export const isRobotNoIndex = (value: string): boolean => value?.toLowerCase() === "noindex";

export const getCanonicalUrl = (path: string): string => {
    // it should strip double slashes, except for the protocol
    return `https://` + process.env.NEXT_PUBLIC_HOSTNAME + path.replace(/([^:]\/)\/+/g, "$1");
};

export const getFirstParagraphFromRichText = (text: Document): string | undefined => {
    if (!text?.content) {
        return undefined;
    }

    const firstParagraph = text.content.find((node) => node.nodeType === "paragraph");

    if (!firstParagraph) {
        return undefined;
    }

    return firstParagraph.content.map((node) => (node as Text).value).join("");
};

const formatDateForLastMod = (date: string): string => {
    const _date = new Date(date);

    const year = _date.getFullYear();
    const month = (_date.getMonth() + 1).toString().padStart(2, "0");
    const day = _date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
};

export type PageWithBareMinimumFields = Entry & {
    fields: {
        slug?: string | null;
        mainCategory?: string | null;
        title?: string | null;
    };
};

const getSlugForEntry = (entry: PageWithBareMinimumFields): string | null => {
    if (entry.sys.contentType.sys.id === "pageHomepage") {
        return "/";
    }

    if (entry.sys.contentType.sys.id === "pageSearch") {
        return "/search";
    }

    if (entry.sys.contentType.sys.id === "pageCategory") {
        return null;
    }

    return String(entry.fields.slug);
};

const stripTrailingSlash = (str: string): string => {
    return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const createArrayOfSitemapEntries = (
    allPagesInAllLocales: Record<string, Array<PageWithBareMinimumFields>>
): Array<ISitemapField> => {
    const results: Array<ISitemapField> = [];

    for (const [currentLocale, entries] of Object.entries(allPagesInAllLocales)) {
        for (const entry of entries) {
            const entryInOtherLocales = Object.entries(allPagesInAllLocales)
                .filter(([otherLocale]) => {
                    return otherLocale !== currentLocale;
                })
                .flatMap(([, localeEntries]) =>
                    localeEntries.filter((localeEntry) => localeEntry.sys.id === entry.sys.id)
                );

            const entrySlug = getSlugForEntry(entry);
            const computedSlug = stripTrailingSlash(
                getComputedSlug(
                    entry.sys.contentType.sys.id,
                    entrySlug,
                    entry.sys.contentType.sys.id === "pageCategory"
                        ? entry.fields.mainCategory
                        : null
                )
            );

            const alternateRefs = entryInOtherLocales.map((entryInOtherLocale) => {
                const entryInOtherLocaleSlug = getSlugForEntry(entryInOtherLocale);
                const computedSlug = stripTrailingSlash(
                    getComputedSlug(
                        entryInOtherLocale.sys.contentType.sys.id,
                        entryInOtherLocaleSlug,
                        entryInOtherLocale.sys.contentType.sys.id === "pageCategory"
                            ? entryInOtherLocale.fields.mainCategory
                            : null
                    )
                );

                return {
                    href: getCanonicalUrl(`/${entryInOtherLocale.sys.locale}/${computedSlug}`),
                    hreflang: entryInOtherLocale.sys.locale as string,
                };
            });

            const selfCanonicalUrl = getCanonicalUrl(
                entry.sys.locale !== process.env.DEFAULT_LOCALE
                    ? `/${entry.sys.locale}/${computedSlug}`
                    : computedSlug
            );

            results.push({
                loc: selfCanonicalUrl,
                lastmod: formatDateForLastMod(entry.sys.updatedAt),
                alternateRefs: [
                    {
                        href: selfCanonicalUrl,
                        hreflang: entry.sys.locale as string,
                    },
                    ...alternateRefs,
                ],
            });
        }
    }

    return results;
};
