import { initGTM, sendToGTM } from "./GoogleTagManager";
import type { PagData, TrackGTMEventProps } from "./type/GoogleTagManagerType";
import {
    getCountryForTracking,
    getLanguageForTracking,
    getPageId,
} from "@/common/helpers/tracking";

const trackGTMEvent = ({
    componentClick = false,
    clickElementName,
    clickElementId,
    customEventAction = "PAGMSH_General_Pageload",
    componentDisplay = false,
    displayElementName,
    displayElementId,
    targetUrl,
    targetType,
    locale,
    asPath,
}: TrackGTMEventProps) => {
    const pageName = getPageId(asPath);

    const pagData: PagData = {
        context: {
            eventAction: customEventAction,
            applicationId: "motorsport-Hub-2",
            language: getLanguageForTracking(locale), // ISO 639-1
            country: getCountryForTracking(locale), // ISO3166 ALPHA2 - or "international"
            currency: "EUR",
            environment: process.env.NODE_ENV === "production" ? "P" : "D",
            timestamp: new Date().toISOString(),
            server: process.env.NEXT_PUBLIC_HOSTNAME,
        },
        visitor: {
            loginStatus: "false",
            deviceBrowserHeight: window.innerHeight.toString(),
            deviceBrowserWidth: window.innerWidth.toString(),
            deviceBrowserBreakpoint: window.innerHeight.toString(),
            deviceBrowserOrientation: window.matchMedia("(orientation: portrait)").matches
                ? "p"
                : "l",
            deviceType: window.matchMedia("(pointer: coarse)").matches ? "mobile" : "desktop",
        },
        pageExperience: {
            pageId: pageName,
            pageName: asPath,
        },
        ...(componentClick && {
            componentClick: {
                clickElementType: targetType,
                clickElementId: clickElementId,
                clickElementName: clickElementName,
                targetUrl: targetUrl,
            },
        }),
        ...(componentDisplay && {
            componentDisplay: {
                displayElementType: targetType,
                displayElementId: displayElementId,
                displayElementName: displayElementName,
            },
        }),
    };

    initGTM({
        dataLayer: pagData,
        dataLayerName: "pagData",
    });

    sendToGTM({
        dataLayerName: customEventAction,
        data: pagData,
    });
};

export default trackGTMEvent;
